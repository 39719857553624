.page {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  background-size: 100% 250px;
  background-repeat: no-repeat;
  background-position: 0 100px;
}

a {
  color: black;
  text-decoration: none;
}

a:hover,
ul.list li:hover .col.full span:first-child {
  color: rgb(170, 0, 170);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

header ul,
header .nav,
footer .social,
footer .under,
content {
  max-width: 1400px;
  margin: 0 auto;
}

.separator-under {
  border-bottom: 1pt solid rgba(0,0,0,0.05);
}

header ul {
  display: flex;
  gap: 8px;
}

.prices {
  font-size: 13px;
  opacity: 0.75;
  padding: 16px 0;
}

.nav {
  display: flex;
  width: 100%;
  padding: 16px 0;
  align-items: center;
  font-size: 15px;
  flex-wrap: wrap;
}

.nav ul {
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  gap: 16px;
}

footer {
  background-color: rgb(248, 248, 248);
  margin-top: 32px;
}

footer .social {
  display: flex;
  padding: 24px 0;
  font-size: 14px;
}

footer .social span {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

footer .social span:before {
  content: "🔝";
  display: block;
  margin-right: 4px;
}

footer .under .flex {
  display: flex;
  padding: 48px 0;
  gap: 16px;
  justify-content: space-between;
}

footer .under .flex .first {
  width: 36%;
  display: flex;
  gap: 32px;
}

footer .under .flex span {
  font-size: 14px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

footer .under .flex .others {
  flex-grow: 1;
  display: flex;
  gap: 16px;
  justify-content: space-evenly;
}

@media screen and (max-width: 799px) {
  footer .under .flex .first,
  footer .under .flex .others {
    width: 100%;
    justify-content: flex-start;
  }
  footer .under .flex {
    flex-direction: column;
  }
}

footer .under .end {
  padding: 16px 0;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .menu-title,
div.title {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}

footer .under .first div:nth-child(2n),
footer .under .others > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

footer .under .first div:nth-child(2n) ul,
footer .under .others > div ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 13px;
}

@media screen and (max-width: 1399px) {
  .header,
  content,
  footer {
    padding: 0 16px;
    max-width: calc(100svw - 32px);
  }
}

content {
  width: 100%;
  flex-grow: 1;
  padding-top: 210px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

section {
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 0 25px rgba(97, 97, 97, 0.1);
  border: 1px solid rgba(201, 201, 201, 0.5);
}

.cols {
  display: flex;
  width: 100%;
  gap: 16px;
}

.cols section {
  width: 50%;
}

@media screen and (max-width: 799px) {
  .cols {
    flex-direction: column;
  }
  .cols section {
    width: calc(100svw - 64px);
  }
}

section.teaser {
  padding: 48px 0;
  font-size: 26px;
  display: flex;
  gap: 2ch;
  justify-content: center;
  align-items: center;
}

a.button {
  display: block;
  padding: 8px 16px;
  font-size: 0.7em;
  font-weight: bold;
  border: 1px solid rgba(201, 201, 201, 0.5);
  border-radius: 8px;
  color: rgb(0,0,0,0.7);
  transition: all 150ms ease-in-out;
}

a.button:hover {
  color: black;
  border-color: rgb(0,0,150);
  background-color: rgba(0,0,150,0.2);
}

ul.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

ul.list li {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  cursor: pointer;
}

ul.list li .icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

ul.list li:hover .icon {
  background-color: rgb(238,238,238);
}

ul.list li .icon img {
  width: 40px;
  height: 40px;
}

.list .col {
  gap: 4px;
}

.list .full {
  flex-grow: 1;
}

.list .small {
  font-size: 12px;
  color: rgba(0,0,0,0.5);
}

.list span.game {
  display: block;
  border: 1px solid purple;
  color: purple;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  background-color: rgba(126, 0, 126, 0.096);
  pointer-events: none;
}

.list span.dex {
  display: block;
  border: 1px solid orange;
  color: orange;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  background-color: rgba(126, 59, 0, 0.096);
  pointer-events: none;
}

.list span.tool {
  display: block;
  border: 1px solid rgba(3, 3, 181, 0.843);
  color: rgba(3, 3, 181, 0.843);
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  background-color: rgba(3, 3, 181, 0.167);
  pointer-events: none;
}

div.title {
  padding-bottom: 16px;
}

.separator {
  margin: 0 -16px 16px;
}

.website-domain {
  font-weight: bold;
  text-transform: uppercase;
}

.pricetag {
  color: rgba(0,0,0,0.6);
}

.pricetag > span {
  color: rgba(0,0,0,1);
}